import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core'
import {Subscription, throwError} from "rxjs"
import {HttpClient, HttpHeaders} from "@angular/common/http";

import { User } from '../models/user.model'
import { Fees } from '../models/fees.model'
import { UserAndFeesService } from '../user-and-fees.service'
import { environment} from "../../environments/environment"

@Component({
  selector: 'app-fees-list',
  templateUrl: './fees-list.component.html',
  styleUrls: ['./fees-list.component.css']
})
export class FeesListComponent implements OnInit {

  private user: User[] = []
    private userSub: Subscription
  fees: Fees[] = []
    feesSub: Subscription
    nan = false
    librarySearchPortalUrl = ''

    @ViewChild('totalToPay') total_sum: ElementRef;

    constructor(private userAndFeesService: UserAndFeesService, private http: HttpClient) { }


  ngOnInit() {
    this.userAndFeesService.getFees()
    this.feesSub = this.userAndFeesService.getFeesUpdateListener()
    .subscribe((feesData) => {
      console.log(feesData)
        // this.fees = this.constructArray(feesData)
        this.fees = feesData
    })


      this.librarySearchPortalUrl = environment.LOP_LIBRARY_SEARCH_PORTAL


      this.userAndFeesService.getUserInfo()
        this.userSub = this.userAndFeesService.getUserUpdateListener()
          .subscribe((userData: User[]) =>{
              console.log(userData)
              this.user = userData
          })
  }

    submitFeesForm(formRef) {
        // post data to backend to process
        console.log('Start of submitFeesForm...')
	console.log("this.total_sum.nativeElement.value: " + this.total_sum.nativeElement.value)
        if(this.total_sum.nativeElement.value > this.fees[0].total_sum)
            return false
        if(this.total_sum.nativeElement.value < 10)
            return false
        if(isNaN(this.total_sum.nativeElement.value)) {
            this.nan = true
            return false
        }
        this.userAndFeesService.postFees(this.total_sum.nativeElement.value, this.user[0].primary_id)
    }

    constructArray(data) {
        const arr = [];
        arr.push(data);
        return arr;
    }

    ngOnDestroy() {
        this.feesSub.unsubscribe()
        this.userSub.unsubscribe()
    }
}
