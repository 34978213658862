import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import { User } from './models/user.model'
import { Fees } from './models/fees.model'
import { environment } from "../environments/environment"
import {Tx} from "./models/tx.model";


@Injectable({
  providedIn: 'root'
})
export class UserAndFeesService {
  private fees: Fees[] = []
    private feesUpdated = new Subject<Fees[]>()
  private user: User[] = []
    private userUpdated = new Subject<User[]>()
    private tx: Tx[] = []
    private txUpdated = new Subject<Tx[]>()

    constructor(private http: HttpClient) { }

  getTx(){
      return  this.http.get<Tx>(
          environment.LOP_BACKEND_BASE_URL + '/_tx'
      )
      .subscribe(txData => {
          this.tx = this.constructArray(txData)
          this.txUpdated.next([...this.tx])
      })
  }

  getTxUpdateListener(){
      return this.txUpdated.asObservable()
  }


  getFees() {
    return this.http.get<Fees[]>(environment.LOP_BACKEND_BASE_URL + '/_user-fees')
        .subscribe(feesData => {
            this.fees = this.constructArray(feesData)
            this.feesUpdated.next([...this.fees])
        })
  }

    getFeesUpdateListener() {
        return this.feesUpdated.asObservable()
    }

    getUserInfo() {
      return this.http.get<User[]>(environment.LOP_BACKEND_BASE_URL + '/_user-info')
            .subscribe(userData => {
              this.user = this.constructArray(userData)
                this.userUpdated.next([...this.user])
            })
    }

    getUserUpdateListener() {
      return this.userUpdated.asObservable()
    }


    postFees(amount, primary_id) {
        // post data to backend to process
        console.log('Start of postFees...')
        this.http.post(
            environment.LOP_BACKEND_BASE_URL + '/_pay',
            { amount: amount, primary_id: primary_id },
            { headers: new HttpHeaders({
                    'Content-Type' : 'application/json'
                })
            }
        )
        .subscribe(
            oss_url => {
                window.location.href = oss_url['OSS_URL']
            }
        )
    }




    constructArray(data) {
        const arr = [];
        arr.push(data);
        return arr;
    }
}
