import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FeesListComponent } from './fees-list/fees-list.component'
import { NotFoundComponent } from './not-found.component'
import {ConfirmComponent} from "./confirm/confirm.component";

const routes: Routes = [
  {
    path: 'fees-list',
    component: FeesListComponent,
  },
    {
      path: 'confirm',
        component: ConfirmComponent
    },
  {
    path: '**',
    component: NotFoundComponent,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
