import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from "@angular/forms";

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { FeesListComponent } from './fees-list/fees-list.component'
import { NotFoundComponent } from './not-found.component';
import { ConfirmComponent } from './confirm/confirm.component'

@NgModule({
  declarations: [
    AppComponent,
    FeesListComponent,
    NotFoundComponent,
    ConfirmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
      FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
