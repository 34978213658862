import { Component, OnInit } from '@angular/core';
import { environment } from "../environments/environment";

@Component({
  selector: 'app-not-found',
  template: '',
  styles: []
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.replace(environment.LOP_LIBRARY_SEARCH_PORTAL)
  }

}
