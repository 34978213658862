import {Component, OnDestroy, OnInit} from '@angular/core';
import { UserAndFeesService } from '../user-and-fees.service'
import {Tx} from "../models/tx.model";
import { environment } from "../../environments/environment";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit, OnDestroy {

    tx: Tx[] = []
    //private tx
    private txSub: Subscription
    librarySearchPortalUrl = ''

  constructor(private userAndFeesService: UserAndFeesService) { }

  ngOnInit() {
    // get transaction info
    this.userAndFeesService.getTx()
      this.txSub = this.userAndFeesService.getTxUpdateListener()
          .subscribe((txData) =>{
              console.log(txData)
              this.tx = txData
          })

      this.librarySearchPortalUrl = environment.LOP_LIBRARY_SEARCH_PORTAL
  }

  ngOnDestroy() {
        this.txSub.unsubscribe()
  }
}
